import { LanguageIsoCode } from "../../composition-functions/language/LanguageIsoCode";
import { log } from "../utils/logging";
import { LanguageError } from "./errors";

export const GLOBAL_FALLBACK_LANGUAGE = LanguageIsoCode.ENGLISH;
export const SUPPORTED_LANGUAGES = {
  default: [LanguageIsoCode.GERMAN, LanguageIsoCode.ITALIAN, LanguageIsoCode.ENGLISH] as const,
  frontend: [LanguageIsoCode.GERMAN, LanguageIsoCode.ITALIAN] as const,
  mandant: [
    LanguageIsoCode.GERMAN,
    LanguageIsoCode.ITALIAN,
    LanguageIsoCode.ENGLISH,
    LanguageIsoCode.CZECH,
    LanguageIsoCode.DUTCH_FLEMISH,
    LanguageIsoCode.FRENCH,
  ] as const,
  request: [LanguageIsoCode.GERMAN, LanguageIsoCode.ITALIAN, LanguageIsoCode.ENGLISH] as const,
};
export type SUPPORTED_LANGUAGES = {
  [k in keyof typeof SUPPORTED_LANGUAGES]: (typeof SUPPORTED_LANGUAGES)[k][number];
};

type ReadFromLanguagesRecordParams<T> = {
  record: Partial<Record<LanguageIsoCode, T>>;
  languages: LanguageIsoCode[];
};
export function readFromLanguagesRecord<T>({ record, languages }: ReadFromLanguagesRecordParams<T>): T {
  for (const language of languages) {
    const languageRecord = record[language];
    if (languageRecord !== undefined) {
      return languageRecord;
    }
  }

  const fallbackLanguageRecord = record[GLOBAL_FALLBACK_LANGUAGE];
  if (fallbackLanguageRecord !== undefined) {
    log({
      error: new LanguageError({
        message: "No data found for the given language, defaulting to fallback language",
        logContext: { contexts: { languages: { languages } } },
      }),
    });
    return fallbackLanguageRecord;
  }

  const firstAvailableLanguageRecord = record[Object.keys(record)[0] as LanguageIsoCode];
  if (firstAvailableLanguageRecord !== undefined) {
    log({
      error: new LanguageError({
        message: "No data found for the given language or fallback language, defaulting to first available language",
        logContext: { contexts: { languages: { languages } } },
      }),
    });
    return firstAvailableLanguageRecord;
  }

  throw new LanguageError({
    message: "Empty LanguagesRecord",
    logContext: { contexts: { languages: { languages } } },
  });
}

type ExtractRecordWithLanguageParams<T> = {
  records: T[];
  languages: LanguageIsoCode[];
};
export function extractRecordWithLanguage<T extends { language: null | LanguageIsoCode }>({
  records,
  languages,
}: ExtractRecordWithLanguageParams<T>): T {
  for (const language of languages) {
    const record = records.find((record) => record.language === language);
    if (record !== undefined) {
      return record;
    }
  }

  const recordInFallbackLanguage = records.find((record) => record.language === GLOBAL_FALLBACK_LANGUAGE);
  if (recordInFallbackLanguage !== undefined) {
    log({
      error: new LanguageError({
        message: "No data found for the given language, defaulting to fallback language",
        logContext: { contexts: { languages: { languages } } },
      }),
    });

    return recordInFallbackLanguage;
  }

  if (records.length > 0) {
    log({
      error: new LanguageError({
        message: "No data found for the given language or fallback language, defaulting to first available language",
        logContext: { contexts: { languages: { languages } } },
      }),
    });

    return records[0];
  }

  throw new LanguageError({
    message: "Empty LanguagesRecord",
    logContext: { contexts: { languages: { languages } } },
  });
}

type GetSupportedLanguageParams = {
  languages: (null | LanguageIsoCode)[];
  supportedLanguages?: undefined | readonly LanguageIsoCode[];
};

/**
 * Gets the first supported language from a given list of languages.
 * This function is useful when you know at compile time which languages are available in your data.
 * It checks the provided list of languages against a list of supported languages and returns the first match.
 * If no supported language is found, it logs an error and returns a global fallback language.
 *
 * @param {Object} params - The parameters for the function.
 * @param {LanguageIsoCode[]} params.languages - The list of languages to check.
 * @param {LanguageIsoCode[]} [params.supportedLanguages=SUPPORTED_LANGUAGES.default] - The list of supported languages. Defaults to SUPPORTED_LANGUAGES.default.
 * @returns {LanguageIsoCode} - The first supported language found in the list, or the global fallback language if none are found.
 */
export function getSupportedLanguage({
  languages,
  supportedLanguages = SUPPORTED_LANGUAGES.default,
}: GetSupportedLanguageParams): LanguageIsoCode {
  for (const supportedLanguage of supportedLanguages) {
    if (languages.includes(supportedLanguage)) {
      return supportedLanguage;
    }
  }

  log({
    error: new LanguageError({
      message: "No supported language found, defaulting to fallback language",
      logContext: { contexts: { languages: { languages, supportedLanguages } } },
    }),
  });

  return GLOBAL_FALLBACK_LANGUAGE;
}
